export default function($t) {
  return [
    {
      name: 'name',
      is_visible: true,
      get short_title() {
        return $t('Units.form_Field_Name')
      },
      get title() {
        return $t('Units.form_Field_Name')
      }
    },
    {
      name: 'code',
      is_visible: true,
      get short_title() {
        return $t('Units.form_Field_Code')
      },
      get title() {
        return $t('Units.form_Field_Code')
      }
    },
    {
      name: 'quantity',
      is_visible: true,
      get short_title() {
        return $t('Units.summary_Quantity')
      },
      get title() {
        return $t('Units.summary_Quantity')
      }
    },
    {
      name: 'parentUnitName',
      is_visible: true,
      get short_title() {
        return $t('Units.summary_ParentUnitName')
      },
      get title() {
        return $t('Units.summary_ParentUnitName')
      }
    },
    {
      name: 'baseQuantity',
      is_visible: true,
      get short_title() {
        return $t('Units.summary_BaseQuantity')
      },
      get title() {
        return $t('Units.summary_BaseQuantity')
      }
    },
    {
      name: 'baseUnitName',
      is_visible: true,
      get short_title() {
        return $t('Units.summary_BaseUnitName')
      },
      get title() {
        return $t('Units.summary_BaseUnitName')
      }
    }
  ]
}
