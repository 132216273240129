import HTTP from '@/utils/http'
import requestHandler from '@/utils/requestHandler'
import { getPaginationNumber } from '@/utils/pagination'
import { Units } from '../model'

export default {
  namespaced: true,

  state: {
    UnitList: new Units(),
    paginatedUnitList: new Units(),
    page: {
      number: 1,
      size: 20,
      total: 0
    },
    kgBaseUnitId: '1caa3710-263f-48c5-b07a-f954108e9046'
  },

  getters: {
    UnitList: state => state.UnitList.list,
    paginatedUnitList: state => state.paginatedUnitList.list,
    UnitListWithOutRecipeUnits: state => state.UnitList.list.filter(unit => !unit.isRecipeUnit),
    BaseUnitList: state => state.UnitList.list.filter(unit => unit.type === 1),
    CustomUnitList: state => state.UnitList.list.filter(unit => unit.type !== 1),
    kgBaseUnitId: state => state.kgBaseUnitId,
    Page: state => ({
      ...state.page,
      last: Math.ceil(state.page.total / state.page.size)
    }),

    /**
     * @description:
     * Given a baseUnitId, returns the base unit and its child units (flat array)
     *
     * @param isRecipeUnit:
     * If isRecipeUnit options is passed `true`, children will all be recipeUnits.
     *
     * @returns {Array}
     * */
    getRelatedUnitList: state => (baseUnitId, isRecipeUnit = false) => {
      return state.UnitList.list.filter(unit => {
        if (unit.id === baseUnitId) {
          return true
        }
        const isRelated = unit.baseUnitId === baseUnitId
        return isRelated && (isRecipeUnit === unit.isRecipeUnit)
      })
    },

    findUnit: state => id => {
      return state.UnitList.list.find(unit => unit.id === id)
    }
  },

  actions: {
    async getUnitList({ commit }) {
      const params = { pageSize: 99999, currentPage: 1, name: ''}
      const loadingName = 'Units/getUnitList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Unit/all', { params })
        },
        success: result => {
          commit('SET_UNIT_LIST', result.data)
          return result
        }
      })
      return results
    },

    async getPaginatedUnitList({ commit, state }, params) {
      const loadingName = 'Units/getPaginatedUnitList'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Unit/all', { params })
        },
        success: result => {
          commit('SET_PAGINATED_UNIT_LIST', result.data)
          return result
        }
      })
      return results
    },

    async createUnit({ commit, dispatch }, unit) {
      const loadingName = 'create_UNIT'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.post('Unit', unit)
        },
        success: result => result
      })
      return results
    },

    async updateUnit({ commit, dispatch }, unit) {
      const loadingName = 'update_UNIT'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.put('Unit', unit)
        },
        success: result => result
      })
      return results
    },

    async deleteUnit({ commit, dispatch }, Id) {
      const loadingName = 'delete_UNIT'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.delete('Unit', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async checkUnitName({ commit, dispatch }, params) {
      const loadingName = 'checkUnitName'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Unit/check/name', { params })
        },
        success: result => result
      })
      return results
    },

    async checkUnitCode({ commit, dispatch }, params) {
      const loadingName = 'checkUnitCode'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Unit/check/code', { params })
        },
        success: result => result
      })
      return results
    },

    async getUnit({ commit }, id) {
      const loadingName = 'Units/getUnit'
      const results = await requestHandler({
        title: loadingName,
        methodName: loadingName,
        method: () => {
          return HTTP.get('Unit', { params: { id } })
        },
        success: result => result
      })
      return results
    }
  },

  mutations: {
    RESET(state) {
      state.UnitList = new Units()
    },

    SET_UNIT_LIST(state, data) {
      state.UnitList.addItems(data.unitList)
    },

    SET_PAGINATED_UNIT_LIST(state, data) {
      state.paginatedUnitList.addItems(data.unitList)
      state.page.number = data.pageNumber
      state.page.size = data.pageSize
      state.page.total = data.totalCount
    }
  }
}
