import Units from '../units'
import FormUnit from '../popup/unit'
import UnitRemove from '../popup/remove'

export default [
  {
    path: '/units',
    name: 'Units',
    component: Units,
    children: [
      {
        path: 'new',
        name: 'UnitNew',
        component: FormUnit
      },
      {
        path: ':id/edit',
        name: 'UnitEdit',
        component: FormUnit,
        props: {
          status: 'edit'
        }
      },
      {
        path: ':id/remove',
        name: 'UnitRemove',
        component: UnitRemove
      }
    ],
    meta: {
      main_menu: true,
      slug: 'units',
      page: 'units',
      category: 'Main_data',
      permissionKey: 'UnitsMenu'
    }
  }
]
