<template lang="pug">
Popup
    template(slot="title")
      template(
        v-if="isEdit"
      ) {{ $t('Units.form_EditTitle') }}
      template(
        v-else
      ) {{ $t('Units.form_CreateTitle') }}

    template(slot="content")
      form.Form(
        id="form-recipe"
        @submit.prevent="onSubmitForm"
      )

        div.Form-item.required
          label.Form-item-label {{ $t('Units.form_Field_Name') }}
          .control
            customTextInput(
              name="name"
              ref="firstField"
              :error="veeErrors.has('name')"
              :data-vv-as="$t('Units.form_Field_Name')"
              v-validate="'required|max:128|verify_name'"
              id="input-units-form-name"
              v-model="form.name"
              :disabled="isBaseUnit"
              :isDelayInput="true"
            )
            Button(
              id="btn-units-popup-loading-name"
              variant="icon loading"
              tabindex="-1",
              v-show="isPendingName"
            )
              Loading(theme="disable")
            Button(
              id="btn-units-popup-ok-name"
              variant="icon check"
              tabindex="-1",
              iconName="icon-check"
              iconClass="icon-check"
              :justIcon="true"
              v-show="validateName"
            )

            showValidateError(
              v-show="veeErrors.has('name')"
              :errorName="veeErrors.first('name')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Units.form_Field_Code') }}
          .control
            customTextInput(
              name="code"
              :error="veeErrors.has('code')"
              :data-vv-as="$t('Units.form_Field_Code')"
              v-validate="'required|max:12|verify_code'"
              id="input-units-code"
              v-model="form.code"
              :disabled="isBaseUnit"
              :isDelayInput="true"
            )

            Button(
              id="btn-units-popup-loading-code"
              variant="icon loading"
              tabindex="-1"
              v-show="isPendingCode"
            )
              Loading(theme="disable")

            Button(
              id="btn-units-popup-ok-code"
              variant="icon check"
              tabindex="-1"
              iconName="icon-check"
              iconClass="icon-check"
              :justIcon="true"
              v-show="validateCode"
            )

            showValidateError(
              v-show="veeErrors.has('code')"
              :errorName="veeErrors.first('code')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Units.form_Field_Quantity') }}
          .control
            customNumberInput(
              name="quantity"
              :error="veeErrors.has('quantity')"
              :data-vv-as="$t('Units.form_Field_Quantity')"
              v-validate="{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}"
              id="input-units-quantity"
              v-model="form.quantity"
              :disabled="isBaseUnit"
            )

            showValidateError(
              v-show="veeErrors.has('quantity')"
              :errorName="veeErrors.first('quantity')"
            )

        div.Form-item.required
          label.Form-item-label {{ $t('Units.form_Field_ParentUnitId') }}
          .control.form-item-select
            Icon(name="icon-down-arrow")

            customSelectInput(
              name="parentUnitId",
              :optionData="UnitListWithOutRecipeUnits"
              v-model="form.parentUnitId"
              :data-vv-as="$t('Units.form_Field_ParentUnitId')"
              :error="veeErrors.has('parentUnitId')"
              id="select-units-parent-unit"
              optionIdName="option-units"
              v-validate="'required|is_not:-1'"
              :disabled="isEdit || isBaseUnit"
            )

            showValidateError(
              v-show="veeErrors.has('parentUnitId')"
              :errorName="veeErrors.first('parentUnitId')"
            )

          p.calculated-base-quantity(
            v-if="shouldShowCalculatedBaseQuantityText"
          )
            span.calculated-base-quantity-text
              | {{ $t('Units.form_CalculatedBaseQuantityText') }}
            | {{ ' ' }}
            span.calculated-base-quantity-value
              | {{ $t('Units.form_CalculatedBaseQuantityValue', { value: calculatedBaseQuantityValue, unit: calculatedBaseQuantityUnit }) }}

        div.Form-item
          label.Form-item-label {{ $t('Units.form_Field_unitNumber') }}
          .control
            customTextInput(
              name="unitNumber"
              id="input-units-unitNumber"
              :error="veeErrors.has('unitNumber')"
              :data-vv-as="$t('Units.form_Field_unitNumber')"
              v-validate= "`very_singularity:Unit/check/unitnumber/?Id=${this.$route.params.id || ''},UnitNumber|max:100`"
              v-model="form.unitNumber"
              :isDelayInput="true"
            )

            showValidateError(
              v-show="veeErrors.has('unitNumber')"
              :errorName="veeErrors.first('unitNumber')"
            )

        div.Form-item(
          v-if="!isEdit"
        )
          div.recipe-unit-checkbox-container.flexbox
            CustomCheckbox(
            id="checkbox-units-is-recipe"
            :key="`is-recipe-checkbox-${Math.round(Math.random() * 1000)}`"
            :label="$t('Units.form_Field_RecipeUnit')"
            :disabled="isEdit"
            v-model="form.isRecipeUnit")

    template(slot="footer")
      div.add-other-items-checkbox-container(
        v-if="!isEdit"
      )
        CustomCheckbox(
        id="checkbox-units-save-and-new"
        :label="$t('Global.formSaveAndNewText', { form: $t('Units.form_Name') })"
        v-model="saveAndNew")

      Button(
        id="btn-units-popup-submit"
        variant="full"
        size="medium"
        type="submit",
        form="form-recipe"
        :disabled="isLoading"
        :isLoading="isLoading"
        success
      )
        span(
          v-show="!isLoading"
        )
          template(
            v-if="isEdit"
          ) {{ $t('Units.form_FinishEditButton') }}
          template(
            v-else
          ) {{ $t('Units.form_FinishCreateButton') }}

</template>

<script>
import { mapFields, Validator } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
import getApiErrorMessage from '@/utils/get-api-error-message'

export default {
  name: 'UnitForm',

  props: {
    status: {
      type: String,
      default: 'new'
    }
  },

  data () {
    return {
      form: {
        name: '',
        code: '',
        quantity: null,
        parentUnitId: '-1',
        isRecipeUnit: false,
        unitNumber: null
      },
      item: {},
      saveAndNew: true
    }
  },

  async created () {
    const verifyName = {
      getMessage: (field, params, data) => {
        return (data && data.message) || 'Something went wrong'
      },
      validate: value => new Promise(resolve => {
        let params = {
          Name: value
        }
        if (this.isEdit) {
          params.Id = this.$route.params.id
        }
        this.checkUnitName(params)
          .then(res => {
            if (res) {
              resolve({ valid: res.data.isAvailable })
            } else {
              resolve({ valid: false })
            }
          })
      })
    }

    const verifyNameMessages = {
      tr: {
        messages: {
          verify_name: field => 'Girdiğiniz birim adı daha önce kullanılmış.'
        }
      }
    }

    Validator.extend('verify_name', verifyName)
    Validator.localize(verifyNameMessages)

    const verifyCode = {
      getMessage: (field, params, data) => {
        return (data && data.message) || 'Something wrong'
      },
      validate: value => new Promise(resolve => {
        let params = {
          code: value
        }
        if (this.isEdit) {
          params.Id = this.$route.params.id
        }
        this.checkUnitCode(params)
          .then(res => {
            if (res) {
              resolve({ valid: res.data.isAvailable })
            } else {
              resolve({ valid: false })
            }
          })
      })
    }

    const verifyCodeMessages = {
      tr: {
        messages: {
          verify_code: field => 'Girdiğiniz kısaltma daha önce kullanılmış.'
        }
      }
    }

    Validator.extend('verify_code', verifyCode)
    Validator.localize(verifyCodeMessages)
  },

  async mounted () {
    if (this.isEdit) await this.init()
    this.formFocus()
  },

  computed: {
    ...mapGetters('Units', {
      UnitListWithOutRecipeUnits: 'UnitListWithOutRecipeUnits',
      findUnit: 'findUnit'
    }),

    ...mapFields({
      flagsName: 'name',
      flagsCode: 'code'
    }),

    isBaseUnit () {
      return this.item.type === 1
    },

    isLoading () {
      return this.$wait.is(['createUnit', 'updateUnit', 'unitsHeaderSubmit'])
    },

    isEdit () {
      return this.status === 'edit'
    },

    isPendingName () {
      return this.flagsName.pending
    },

    isPendingCode () {
      return this.flagsCode.pending
    },

    validateName () {
      return this.flagsName.valid && !this.isPendingName
    },

    validateCode () {
      return this.flagsCode.valid && !this.isPendingCode
    },

    shouldShowCalculatedBaseQuantityText () {
      return (
        this.form.quantity &&
          this.form.parentUnitId &&
          !this.veeErrors.has('quantity') &&
          !this.veeErrors.has('parentUnitId')
      )
    },

    calculatedBaseQuantityValue () {
      const parentUnit = this.findUnit(this.form.parentUnitId)
      const value = this.form.quantity * parentUnit.baseQuantity
      if (parseInt(value, 10) !== value) {
        return value.toString().slice(0, 5) // "integer" + "." + "precision:3" = 5 chars
      }
      return value
    },

    calculatedBaseQuantityUnit () {
      const parentUnit = this.findUnit(this.form.parentUnitId)
      return parentUnit.baseUnitName
    }
  },

  methods: {
    ...mapActions('Units', [
      'createUnit',
      'updateUnit',
      'checkUnitName',
      'checkUnitCode',
      'getUnit'
    ]),

    ...mapActions('Notify', [
      'notifyShow'
    ]),

    async init () {
      let item = {}
      if (this.$route.params.isPageRefreshed) {
        item = this.findUnit(this.$route.params.id)
        this.setFormItem(item)
      } else {
        this.getUnit(this.$route.params.id)
          .then(res => {
            item = res.data.unit
            this.setFormItem(item)
          })
      }
    },

    setFormItem (item) {
      this.form.name = item.name
      this.form.code = item.code
      this.form.quantity = item.quantity
      this.form.unitNumber = item.unitNumber
      this.form.parentUnitId = item.parentUnitId
      this.form.isRecipeUnit = item.isRecipeUnit
      this.item = item
    },

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    formFocus () {
      this.$refs.firstField.focusCustomInput()
    },

    resetForm () {
      this.form = {
        name: '',
        code: '',
        quantity: '',
        parentUnitId: '-1',
        isRecipeUnit: false,
        unitNumber: null
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      await this.$validator.validateAll().then(async isValid => {
        if (!isValid) return
        let unit = {
          ...this.form
        }
        if (this.isEdit) {
          unit.id = this.$route.params.id
          await this.updateUnit(unit).then(res => {
            if (res.status === 200) {
              const message = this.$t('Global.notification_Updated', { name: unit.name })
              this.notifyShow({ message })
              this.$emit('getList')
              this.close()
            } else {
              const message = getApiErrorMessage(this, res.data.code)
              this.notifyShow({ message })
            }
          })
        } else {
          await this.createUnit(unit).then(res => {
            if (res) {
              const message = this.$t('Global.notification_Created', { name: unit.name })
              this.notifyShow({ message })
              this.$emit('getList')

              if (this.saveAndNew) {
                this.resetForm()
                this.formFocus()
                this.$nextTick(() => {
                  this.$validator.reset()
                })
              } else {
                this.close()
              }
            }
          })
        }
      })
    }, 'unitsHeaderSubmit')
  }
}
</script>

<style lang="scss" scoped>

  .calculated-base-quantity {
    margin-left: 10px;
    margin-top: 10px;
    font-size: 14px;

    &-text {
      color: $color-success;
      font-weight: bold;
    }
  }
</style>
