<template lang="pug">
DeleteAlert(
    :item                     ="item",
    :fields                   ="fields",
    :tableName                ="$t('Units.remove_TableName')",
    loading                   ="Units/delete_UNIT",
    @delegateOnAlertRemoveOk  ="itemRemove"
  )
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'UnitRemove',

  data () {
    return {
      fields: [{
        name: 'name',
        is_visible: true,
        short_title: this.$t('Units.remove_TableColumn_Name_Short'),
        title: this.$t('Units.remove_TableColumn_Name')
      }, {
        name: 'code',
        is_visible: true,
        short_title: this.$t('Units.remove_TableColumn_Code_Short'),
        title: this.$t('Units.remove_TableColumn_Code')
      }, {
        name: 'quantity',
        is_visible: true,
        short_title: this.$t('Units.remove_TableColumn_Quantity_Short'),
        title: this.$t('Units.remove_TableColumn_Quantity')
      }, {
        name: 'parentUnitName',
        is_visible: true,
        short_title: this.$t('Units.remove_TableColumn_ParentUnitName_Short'),
        title: this.$t('Units.remove_TableColumn_ParentUnitName')
      }],
      item: {}
    }
  },

  async mounted () {
    if (this.$route.params.item) {
      this.item = this.$route.params.item
    } else {
      this.getUnit(this.$route.params.id)
        .then(res => {
          this.item = this.UnitList.find(item => item.id === res.data.unit.id)
        })
    }
  },

  computed: {
    ...mapGetters('Units', [
      'findUnit',
      'UnitList'
    ])
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('Units', [
      'getUnitList',
      'deleteUnit',
      'getUnit'
    ]),

    close () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      await this.deleteUnit(this.item.id).then(async res => {
        if (res) {
          const message = this.$t('Global.notification_Deleted', { name: this.item.name })
          this.notifyShow({ message })
          this.$emit('getList')
          this.close()
        }
      })
    }, 'unitsHeaderRemove')
  }
}
</script>

<style lang="scss">
</style>
