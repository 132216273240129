var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c(
        "template",
        { slot: "title" },
        [
          _vm.isEdit
            ? [_vm._v(_vm._s(_vm.$t("Units.form_EditTitle")))]
            : [_vm._v(_vm._s(_vm.$t("Units.form_CreateTitle")))],
        ],
        2
      ),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          {
            staticClass: "Form",
            attrs: { id: "form-recipe" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.onSubmitForm.apply(null, arguments)
              },
            },
          },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Units.form_Field_Name"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:128|verify_name",
                        expression: "'required|max:128|verify_name'",
                      },
                    ],
                    ref: "firstField",
                    attrs: {
                      name: "name",
                      error: _vm.veeErrors.has("name"),
                      "data-vv-as": _vm.$t("Units.form_Field_Name"),
                      id: "input-units-form-name",
                      disabled: _vm.isBaseUnit,
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingName,
                          expression: "isPendingName",
                        },
                      ],
                      attrs: {
                        id: "btn-units-popup-loading-name",
                        variant: "icon loading",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.validateName,
                        expression: "validateName",
                      },
                    ],
                    attrs: {
                      id: "btn-units-popup-ok-name",
                      variant: "icon check",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                      justIcon: true,
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("name"),
                        expression: "veeErrors.has('name')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("name") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Units.form_Field_Code"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:12|verify_code",
                        expression: "'required|max:12|verify_code'",
                      },
                    ],
                    attrs: {
                      name: "code",
                      error: _vm.veeErrors.has("code"),
                      "data-vv-as": _vm.$t("Units.form_Field_Code"),
                      id: "input-units-code",
                      disabled: _vm.isBaseUnit,
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                  _c(
                    "Button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isPendingCode,
                          expression: "isPendingCode",
                        },
                      ],
                      attrs: {
                        id: "btn-units-popup-loading-code",
                        variant: "icon loading",
                        tabindex: "-1",
                      },
                    },
                    [_c("Loading", { attrs: { theme: "disable" } })],
                    1
                  ),
                  _c("Button", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.validateCode,
                        expression: "validateCode",
                      },
                    ],
                    attrs: {
                      id: "btn-units-popup-ok-code",
                      variant: "icon check",
                      tabindex: "-1",
                      iconName: "icon-check",
                      iconClass: "icon-check",
                      justIcon: true,
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("code"),
                        expression: "veeErrors.has('code')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("code") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Units.form_Field_Quantity"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customNumberInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: {
                          required: true,
                          decimal: _vm.$getConst("DECIMAL_FOR_QUANTITY"),
                          greater_than: 0,
                          max: _vm.$getConst("MAX_DIGITS_FOR_QUANTITY"),
                        },
                        expression:
                          "{required: true, decimal: $getConst('DECIMAL_FOR_QUANTITY'), greater_than:0, max: $getConst('MAX_DIGITS_FOR_QUANTITY')}",
                      },
                    ],
                    attrs: {
                      name: "quantity",
                      error: _vm.veeErrors.has("quantity"),
                      "data-vv-as": _vm.$t("Units.form_Field_Quantity"),
                      id: "input-units-quantity",
                      disabled: _vm.isBaseUnit,
                    },
                    model: {
                      value: _vm.form.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "quantity", $$v)
                      },
                      expression: "form.quantity",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("quantity"),
                        expression: "veeErrors.has('quantity')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("quantity") },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Units.form_Field_ParentUnitId"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item-select" },
                [
                  _c("Icon", { attrs: { name: "icon-down-arrow" } }),
                  _c("customSelectInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|is_not:-1",
                        expression: "'required|is_not:-1'",
                      },
                    ],
                    attrs: {
                      name: "parentUnitId",
                      optionData: _vm.UnitListWithOutRecipeUnits,
                      "data-vv-as": _vm.$t("Units.form_Field_ParentUnitId"),
                      error: _vm.veeErrors.has("parentUnitId"),
                      id: "select-units-parent-unit",
                      optionIdName: "option-units",
                      disabled: _vm.isEdit || _vm.isBaseUnit,
                    },
                    model: {
                      value: _vm.form.parentUnitId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "parentUnitId", $$v)
                      },
                      expression: "form.parentUnitId",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("parentUnitId"),
                        expression: "veeErrors.has('parentUnitId')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("parentUnitId") },
                  }),
                ],
                1
              ),
              _vm.shouldShowCalculatedBaseQuantityText
                ? _c("p", { staticClass: "calculated-base-quantity" }, [
                    _c(
                      "span",
                      { staticClass: "calculated-base-quantity-text" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Units.form_CalculatedBaseQuantityText")
                          )
                        ),
                      ]
                    ),
                    _vm._v(_vm._s(" ")),
                    _c(
                      "span",
                      { staticClass: "calculated-base-quantity-value" },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Units.form_CalculatedBaseQuantityValue", {
                              value: _vm.calculatedBaseQuantityValue,
                              unit: _vm.calculatedBaseQuantityUnit,
                            })
                          )
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "Form-item" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("Units.form_Field_unitNumber"))),
              ]),
              _c(
                "div",
                { staticClass: "control" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: `very_singularity:Unit/check/unitnumber/?Id=${
                          this.$route.params.id || ""
                        },UnitNumber|max:100`,
                        expression:
                          "`very_singularity:Unit/check/unitnumber/?Id=${this.$route.params.id || ''},UnitNumber|max:100`",
                      },
                    ],
                    attrs: {
                      name: "unitNumber",
                      id: "input-units-unitNumber",
                      error: _vm.veeErrors.has("unitNumber"),
                      "data-vv-as": _vm.$t("Units.form_Field_unitNumber"),
                      isDelayInput: true,
                    },
                    model: {
                      value: _vm.form.unitNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "unitNumber", $$v)
                      },
                      expression: "form.unitNumber",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("unitNumber"),
                        expression: "veeErrors.has('unitNumber')",
                      },
                    ],
                    attrs: { errorName: _vm.veeErrors.first("unitNumber") },
                  }),
                ],
                1
              ),
            ]),
            !_vm.isEdit
              ? _c("div", { staticClass: "Form-item" }, [
                  _c(
                    "div",
                    { staticClass: "recipe-unit-checkbox-container flexbox" },
                    [
                      _c("CustomCheckbox", {
                        key: `is-recipe-checkbox-${Math.round(
                          Math.random() * 1000
                        )}`,
                        attrs: {
                          id: "checkbox-units-is-recipe",
                          label: _vm.$t("Units.form_Field_RecipeUnit"),
                          disabled: _vm.isEdit,
                        },
                        model: {
                          value: _vm.form.isRecipeUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isRecipeUnit", $$v)
                          },
                          expression: "form.isRecipeUnit",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          !_vm.isEdit
            ? _c(
                "div",
                { staticClass: "add-other-items-checkbox-container" },
                [
                  _c("CustomCheckbox", {
                    attrs: {
                      id: "checkbox-units-save-and-new",
                      label: _vm.$t("Global.formSaveAndNewText", {
                        form: _vm.$t("Units.form_Name"),
                      }),
                    },
                    model: {
                      value: _vm.saveAndNew,
                      callback: function ($$v) {
                        _vm.saveAndNew = $$v
                      },
                      expression: "saveAndNew",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "Button",
            {
              attrs: {
                id: "btn-units-popup-submit",
                variant: "full",
                size: "medium",
                type: "submit",
                form: "form-recipe",
                disabled: _vm.isLoading,
                isLoading: _vm.isLoading,
                success: "",
              },
            },
            [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _vm.isEdit
                    ? [_vm._v(_vm._s(_vm.$t("Units.form_FinishEditButton")))]
                    : [_vm._v(_vm._s(_vm.$t("Units.form_FinishCreateButton")))],
                ],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }