import BaseClass from '@/utils/baseclass'

// RECIPES

export class Units extends BaseClass {
  addItem(item) {
    this.list.push(new Unit(item))
  }

  updateItem(newItem) {
    let item = this.findItem(newItem.id)
    if (item) {
      item.id = newItem.id
      item.parentUnitId = newItem.parentUnitId
      item.baseUnitId = newItem.baseUnitId
      item.name = newItem.name
      item.code = newItem.code
      item.quantity = newItem.quantity
      item.baseQuantity = newItem.baseQuantity
      item.type = newItem.type
      item.isRecipeUnit = newItem.isRecipeUnit
      item.parentUnitCode = newItem.parentUnitCode
      item.parentUnitId = newItem.parentUnitId
      item.parentUnitName = newItem.parentUnitName
      item.baseUnitCode = newItem.baseUnitCode
      item.baseUnitId = newItem.baseUnitId
      item.baseUnitName = newItem.baseUnitName
      item.unitNumber = newItem.unitNumber
    }
  }
}

class Unit {
  constructor(props) {
    this.id = props.id
    this.parentUnitId = props.parentUnitId
    this.baseUnitId = props.baseUnitId
    this.name = props.name
    this.code = props.code
    this.quantity = props.quantity
    this.baseQuantity = props.baseQuantity
    this.type = props.type
    this.isRecipeUnit = props.isRecipeUnit
    this.parentUnitCode = props.parentUnitCode
    this.parentUnitId = props.parentUnitId
    this.parentUnitName = props.parentUnitName
    this.baseUnitCode = props.baseUnitCode
    this.baseUnitId = props.baseUnitId
    this.baseUnitName = props.baseUnitName
    this.unitNumber = props.unitNumber
  }
}
